/**
 * Single page
 */

// Import statements
import React from "react"
import {graphql} from "gatsby"
import parse from "html-react-parser"
import "../styles/files/single-post.scss"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
//import "../styles/@wordpress/block-library/build-style/style.styles"
//import "../styles/@wordpress/block-library/build-style/theme.styles"
import Layout from "../components/layout"
import Body from "../components/body"
import Seo from "../components/common/seo"

/**
 * Define the PageTemplate constant
 * @param previous
 * @param next
 * @param post
 * @param comments
 * @returns {JSX.Element}
 * @constructor
 */
const PageTemplate = ({data: {previous, next, post, comments}}) => {

    // return the information
    return (
        <Layout>
            <Body bodyClass="single single-page" />
            <Seo title={post.title} description={post.excerpt} />

            <article className="post">

                <header className="row">
                    <div className="col-md-12 col-lg-10 offset-lg-1">
                        <h1 className="entry-title" itemProp="name">{parse(post.title)}</h1>
                    </div>
                </header>

                <div className="row single-content" id="post-content">
                    <div className="col-md-10 offset-md-1 col-no-padding" id="post-content-body">

                        <div id="single-blog-text">
                            <div id="result"></div>
                            {!!post.content && parse(post.content)}
                        </div>
                    </div>
                </div>

            </article>

        </Layout>
    )
}

/**
 * Export the PageTemplate variable
 */
export default PageTemplate

/**
 * Get the single post
 * @type {StaticQueryDocument}
 */
export const pageQuery = graphql`
  query PageById(
    $id: String!
  ) {
    post: wpPage(id: { eq: $id }) {
        id
        content
        title
        date(formatString: "MMMM DD, YYYY")
      }
  }
`
